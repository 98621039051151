<template>
  <div class="unstruct-blog">
    <header class="blog-header">
      <router-link :to="{ name: 'chat' }" class="home-link">
        <img src="/static/unstruct-logo-new.png" alt="UnStruct Logo" width="55" height="55" />
      </router-link>
      <h1 class="site-title">UnStruct.ai Blog</h1>
    </header>

    <main class="blog-content">
      <nav class="blog-nav">
        <ul>
          <li v-for="post in blogPosts" :key="post.id">
            <a href="#" @click.prevent="showPost(post.id)" :class="{ 'active': currentPostId === post.id }">
              {{ post.title }}
            </a>
            <small class="blog-post-date">{{ post.date }}</small>
          </li>
        </ul>
      </nav>

      <transition name="fade" mode="out-in">
        <article v-if="currentPost" class="blog-post" :key="currentPost.id">
          <div v-html="currentPost.content"></div>
        </article>
        <div v-else class="no-post-selected">
          <p>Select a post from the list to start reading.</p>
        </div>
      </transition>
    </main>

    <footer class="blog-footer">
      <p>&copy; {{ new Date().getFullYear() }} UnStruct.ai. All rights reserved.</p>
    </footer>

    <button v-show="showBackToTop" @click="scrollToTop" class="back-to-top" aria-label="Back to top">
      ↑
    </button>
  </div>
</template>

<script>
export default {
  name: 'UnstructBlog',
  inject: ['updateTitle'],
  data() {
    return {
      currentPostId: null,
      showBackToTop: false,
      blogPosts: [
        {
          id: 'launch-announcement',
          title: "UnStruct.ai Launch Announcement",
          date: "Oct 17, 2024",
          content: `
            <div class="blog-post-header">
              <span>Written by UnStruct</span>
              <span>•</span>
              <span>Published on Oct 17, 2024</span>
            </div>
            <h2>🚀 Excited to announce the launch and general availability of UnStruct.ai!</h2>

            <p>If you use Google search, or any of the AI chatbots, give UnStruct a try. Ask a tough question, paste a Youtube video or upload a PDF/image. Choose your sources (e.g. search results only from a particular site); shape your understanding with many customizable settings.</p>

            <p>Turning a promising POC into a GA product was challenging, but we're thrilled with the result. Here are some features that might interest you:</p>

            <ul>
              <li>Contextual analysis across diverse data sources (websites, files, images, Youtube videos, and most entities you interact with). Everything is an interactive active object (IAO) in UnStruct.</li>
              <li>Customizable RAG strategies (including in-house developed ECRAG: Entity-Centric Retrieval-Augmented Generation).</li>
              <li>Choose from up to 10 LLM models that best fit your needs (w/custom prompts)</li>
              <li>Intuitive and expandable knowledge hierarchies for context aggregation and grouping</li>
              <li>Follow-up questions maintain context as you explore, enhancing decision-making and information extraction (switch context as necessary)</li>
            </ul>

            <p>Clink of the UnStruct logo above to learn more and start your journey towards smarter, context-aware data exploration.</p>

            <p>We'd love to hear your thoughts and feedback!</p>
          `
        },
        {
          id: 'ecrag-overview',
          title: "Entity-Centric Retrieval-Augmented Generation (ECRAG)",
          date: "Oct 17, 2024",
          content: `
          <div class="blog-post-header">
            <span>Written by UnStruct</span>
            <span>•</span>
            <span>Published on Oct 17, 2024</span>
          </div>
            <h2>The ECRAG Story: Enhancing decision-making and information extraction</h2>

            <p>In the vast digital landscape of the 21st century, we found ourselves drowning in a sea of information. Traditional search engines and AI chatbots, while powerful, often left us wanting more. They were like fishermen casting wide nets, bringing in everything - the good, the bad, and the irrelevant. We knew there had to be a better way.</p>

            <p>Enter Entity-Centric Retrieval-Augmented Generation, or ECRAG. Born from a simple yet profound realization: garbage in, garbage out. We understood that Large Language Models (LLMs) were incredibly powerful, but they had their limits. They craved relevant context, struggled with information overload, and often lost recall with too much data.</p>

            <p>So, we asked ourselves: What if we could present LLMs with the most relevant, context-aware data, allowing them to shine at what they do best?</p>

            <p>This question led us on a journey, resulting in ECRAG - a system that doesn't just retrieve information, but understands it. Here's how our story unfolds:</p>

            <h3>Chapter 1: The Birth of Entities and Interactive Active Objects (IAOs)</h3>

            <p>We began by reimagining how information is represented. In the world of ECRAG, every piece of information - be it from a website, a PDF, an image, or a YouTube video - becomes an entity that is mapped to an Interactive Active Object (IAO). These IAOs are not static; they're living, breathing entities in the digital realm.</p>

            <p>Each entity is enriched with over tens metadata attributes, creating a multi-dimensional profile that captures nuances traditional systems often miss. It's like giving each piece of information its own unique fingerprint.</p>

            <h3>Chapter 2: Building a Dynamic Information Ecosystem</h3>

            <p>With our entities and IAOs in place, we needed a way to make them work together. We developed an intuitive and expandable knowledge hierarchy system. This allows for context aggregation and grouping, creating a web of interconnected information that evolves and adapts.</p>

            <p>As you explore and ask follow-up questions, the system maintains context, enhancing decision-making and information extraction. It's like having a conversation with someone who not only remembers what you've discussed but also understands the underlying connections.</p>

            <h3>Chapter 3: Overcoming the Context Window Challenge</h3>

            <p>Traditional RAG systems often stumble when faced with the limitations of context windows. ECRAG takes a smarter approach. Through adaptive RAG strategies and leveraging those multi-dimensional attributes, we've found ways to compress and prioritize information.</p>

            <p>Imagine being able to distill the essence of a lengthy document into a concentrated form, preserving the most relevant bits for any given query. That's what ECRAG does, ensuring that even with limited context windows, the most pertinent information always comes through.</p>

            <h3>Chapter 4: Customization and Flexibility</h3>

            <p>We understood that one size doesn't fit all in the world of information retrieval. That's why we made ECRAG customizable. Users can choose their sources, shape their understanding with various settings, and even select from up to 10 different LLM models that best fit their needs.</p>

            <p>It's like having a team of expert researchers at your fingertips, each specializing in different areas, ready to assist you with custom-tailored insights.</p>

            <h3>The Road Ahead</h3>

            <p>As we look to the future, we see ECRAG as more than just a tool - it's a new way of interacting with the digital world. From powering next-generation search engines to creating AI assistants that truly understand context, the possibilities are endless.</p>

            <p>We invite you to join us on this journey. Click on the logo above to try UnStruct. Ask tough questions, upload diverse content, and experience the power of context-aware, adaptive information retrieval. Together, we're not just searching for information - we're understanding it, connecting it, and using it to make better decisions in an increasingly complex world.</p>

            <p>The story of ECRAG is still being written, and we can't wait to see where it goes next. What chapters will you add to this ongoing narrative of innovation in information retrieval?</p>
          `
        },
      ]
    };
  },
  computed: {
    currentPost() {
      return this.blogPosts.find(post => post.id === this.currentPostId);
    },
    basePath() {
      const pathSegments = this.$route.path.split('/');
      const blogIndex = pathSegments.indexOf('blog');
      if (blogIndex !== -1) {
        return pathSegments.slice(0, blogIndex + 1).join('/');
      } else {
        return `${pathSegments.join('/')}/blog`;
      }
    }
  },
  mounted() {
    this.updateTitle('Unstruct.ai Blog');
  },
  methods: {
    showPost(id) {
      this.currentPostId = id;
      this.$router.push(`${this.basePath}/${id}`);
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.showBackToTop = window.scrollY > 300;
    }
  },
  created() {
    const postId = this.$route.params.id;
    if (postId && this.blogPosts.some(post => post.id === postId)) {
      this.currentPostId = postId;
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$route'(to) {
      const postId = to.params.id;
      if (postId && this.blogPosts.some(post => post.id === postId)) {
        this.currentPostId = postId;
      } else {
        this.currentPostId = null;
      }
    }
  }
}
</script>

<style>
.unstruct-blog {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 350;
  letter-spacing: 0.0071428571em;
}

.blog-header {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #777;
}

.blog-post h1, .blog-post h2, .blog-post h3 {
  font-size: 1.155em;
  font-weight: 550;
  letter-spacing: 0.01em;
  margin-bottom: 0.75rem !important;
  margin-top: 1.5rem !important;
}

.blog-post strong, .blog-post b {
    font-weight: 550;
    letter-spacing: 0.01em;
}


.blog-post ul, .blog-post ol {
  padding-left: 40px; /* space between bullet and left edge */
  margin-bottom: 5px;
}

.blog-post ul li, .blog-post ol li {
  margin-bottom: 5px;
  padding-left: 20px; /* space between bullet and text */
  line-height: 1.6;
}

.blog-post ul li:last-child {
  margin-bottom: 0;
}

.blog-post ul li::before {
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1.5em;
  margin-right: 0.5em;
  color: inherit; /* This ensures the bullet inherits the text color */
}

.blog-post li:last-child {
  margin-bottom: 0;
}

.home-link {
  margin-right: 20px;
}

.site-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.blog-content {
  display: flex;
  margin-top: 40px;
}

.blog-nav {
  width: 250px;
  margin-right: 40px;
}

.blog-nav ul {
  list-style-type: none;
  padding: 0;
}

.blog-nav li {
  margin-bottom: 15px;
}

.blog-nav a {
  text-decoration: none;
  color: #555 !important;
  font-weight: 500;
  transition: color 0.3s ease;
}

.blog-nav a:hover, .blog-nav a.active {
  color: #0066cc !important;
}

.blog-post {
  flex: 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 30px;
}

.no-post-selected {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  border-radius: 8px;
  font-style: italic;
  color: #777;
}

.blog-footer {
  margin-top: 40px;
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #777;
  font-size: 14px;
  color: #777;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0.7;
}

.back-to-top:hover {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}


p {
  margin-bottom: 15px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .blog-content {
    flex-direction: column;
  }

  .blog-nav {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .blog-nav ul {
    display: flex;
    flex-wrap: wrap;
  }

  .blog-nav li {
    margin-right: 15px;
  }
}

.blog-post-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
  color: #777;
  margin-bottom: 1rem;
}

.blog-post-header span {
  margin-right: 0.5rem;
}

.blog-post-header span:last-child {
  margin-right: 0;
}

.blog-post-date {
  font-size: 0.8em;
  color: #777;
  margin-top: 4px;
}
</style>
